.home-section-five {
  height: 100%;
  background-color: var(--background-color-green);
  .label {
    margin-left: 0 !important;
  }
    .title-home-section-five {
      margin-top: 2vh;
      font-weight: 300;
      font-family: var(--font-family-fraunces) !important;
    }
  
    .text-home-section-five {
      font-family: var(--font-family-instrument);
      font-size: 4vw;
      font-weight: 400;
    }
}

@media (max-width: 360px) {
  .home-section-five {
  }
}


@media (min-width: 375px) {
  .home-section-five {
  }
}

@media (min-width: 427px) {
  .home-section-five {
  }
}

@media (min-width: 1120px) { 
  .home-section-five {
    .text-home-section-five {
      font-size: 1.5vw;
      p {
        margin-top: 2vh;
      }
    }
  }
}

@media (min-width: 1280px) { 
  .home-section-five {
    .text-home-section-five {
      margin-top: 3vh;
      p {
        margin-top: 3vh;
      }
    }
  }
}

@media (min-width: 1559px) { 
  .home-section-five {
    .text-home-section-five {
      p {
        margin-top: 5vh;
      }
    }
  }
}

@media (min-width: 1761px) { 
  .home-section-five {
    .text-home-section-five {
      p {
        margin-top: 6vh;
      }
    }
  }
}



