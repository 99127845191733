.section-directors {
  z-index: var(--z-index-section);
  overflow: hidden;
  position: relative;
}

.title-directors {
  margin-top: 100vh;
  font-family: var(--font-family-fraunces);
}

.elipse {
  position: absolute;
  z-index: 0;
  width: 40vw;
  padding-top: 10vh;
}

.elipse-1 {
  z-index: 0;
  margin-left: -15vw;
}

.elipse-2 {
  margin-left: -5vw;
  ;
}

.elipse-3 {
  margin-left: 5vw;
}

.title-directors-nossos {
  margin-left: 40vw;
  position: absolute;
  font-size: 12vw;
  font-family: var(--font-family-fraunces);
  color: var(--background-color-blue);
  z-index: 2;
  font-weight: 300 !important;
}

.subtitle-talentos {
  padding-top: 25vh;
}

.title-directors-diretores {
  margin-left: 10vw;
  margin-top: 3vh;
  position: absolute;
  font-size: 12vw;
  font-family: var(--font-family-fraunces);
  color: var(--background-color-blue);
  font-weight: 300 !important;
  z-index: 2;
}

.image-diretores {
  z-index: 1;
  margin-top: 1vh;
}

.title-director {
  font-size: 12vw;
  font-family: var(--font-family-fraunces);
  font-weight: 300 !important;
  line-height: 1;
  margin-top: 5vh;
}

.description-director {
  font-style: italic;
  font-size: 5.5vw;
  font-family: var(--font-family-fraunces);
  font-weight: 300 !important;
  line-height: 1;
}

.director {
  padding-bottom: 12vh;
}

.link-trabalhos-talentos {
  color: var(--background-color-blue) !important;
  text-decoration: underline !important;
  text-underline-offset: 0.8vh;
}

@media (min-width:360px) {
  .elipse {
    margin-top: -8vh;
  }

  .link-trabalhos-talentos {
    font-size: 4vw !important;
  }
}

@media (min-width: 1120px) {

  .title-directors {
    margin-top: 50px;

    h4 {
      font-size: 5vw;
    }
  }

  .elipse {
    width: 35vw;
  }

  .title-directors-nossos {
    margin-top: 0vh;
    margin-left: 49vw;

    h1 {
      font-size: 14vw;
    }
  }

  .title-directors-diretores {
    margin-left: 25vw;
    margin-top: 18vh;

    h1 {
      font-size: 12vw;
      z-index: 2;
    }
  }

  .image-diretores {
    z-index: 1;
    margin-top: 15vh;
  }

  .title-director {
    font-size: 5vw;
  }

  .description-director {
    margin-top: 8vh;
    font-size: 3vw;
  }

  .director {
    p {
      padding-bottom: 5vh;
    }
  }

  .link-trabalhos-talentos {
    font-size: 1.4vw !important;
  }
}