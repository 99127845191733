.section-movie {
  background-color: var(--background-color-blue);
  color: white;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.title-movie {
  margin-top: 13vh;
  font-size: 5vw;
  font-family: var(--font-family-fraunces);
  font-weight: 300 !important;
}

.subtitle-movie {
  margin-top: 1vh;
  font-weight: 400;
  font-family: var(--font-family-instrument);
  font-size: 1.15vw !important;
}

.link-movie {
  color: var(--background-color-green);
  font-style: italic;
}

.subtitle-movie-right {
  font-family: var(--font-family-fraunces);
  font-size: 3vw;
  line-height: 1;
  font-weight: 300;
}

.link-movie {
  color: var(--background-color-green);
  font-style: italic;
}

.second-section-movie {
  margin-top: 5vh;
  margin-bottom: 10vh;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.subtitle-movie {
  font-family: var(--font-family-instrument);
  font-size: 5.3vw;
}

.second-title-movie {
  z-index: 2;
  font-family: var(--font-family-fraunces);
  font-size: 12vw;
  font-weight: 300;
}

.title-one {
  margin-top: 10vh;
}

.title-two {
  margin-bottom: 10vh;
}

.elipse {
  position: absolute;
  width: 40vw;
  margin-top: 0vh;
  transform: rotate(100deg);
}

.elipse-1 {
  margin-left: -15vw;
  z-index: 1;
}

.elipse-2 {
  margin-left: -5vw;
  z-index: 1;
}

.elipse-3 {
  margin-left: 5vw;
  z-index: 1;
}


@media (min-width: 360px) {
  .title-movie {
    font-size: 6vw;
  }

  .subtitle-movie {
    margin-top: 4vh;
    font-size: 9vw;
    margin-right: 0vw;
  }

  .subtitle-movie-right {
    font-size: 8vw;
  }

  .title-one {
    margin-top: 0vh;
  }

  .title-two {
    margin-top: -6vh;
  }
}




@media (min-width: 820px) {
  .title-about {
    margin-top: 10vh;
  }
}

@media (min-width: 1120px) {
  .title-movie {
    font-family: var(--font-family-fraunces);
  }

  .subtitle-movie {
    margin-top: 12vh !important;
    margin-right: 0vw;
    line-height: 1;
    font-size: 1.15vw !important;

  }

  .subtitle-movie-right {
    margin-top: 12vh !important;
    font-size: 3.5vw;
  }

  .second-section-movie {
    margin-top: 10vh;
  }

  .text-about-impossible {
    margin-top: 5vh;
    font-family: var(--font-family-fraunces);
  }

  .subtitle-movie {
    margin-top: 0vh;
    padding-bottom: 8vh;
    margin-right: 0vw;
    font-size: 9.5vw;
  }

  .text-about-impossible {
    margin-right: 4vw;
  }

  .title-two {
    margin-top: -10vh;
  }
}

@media (min-width: 1120px) {
  .subtitle-movie {
    margin-top: 8vh;
    padding-bottom: 5vh;
  }
}