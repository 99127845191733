/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */


html,
body {
  margin-left: 0;
  padding: 0;
  height: 100vh;
}

a {
  color: black !important;
  /* Altera a cor do texto para preto */
  text-decoration: none !important;
  /* Remove o sublinhado padrão */
}

a:hover {
  text-decoration: none;
  /* Garante que o sublinhado não apareça */
}

.see-vimeo {
  font-weight: 300 !important;
  line-height: 1;
  margin-top: 10vh;
}

.video-top {
  padding-top: 15vh;
}

.custom-column {
  display: flex;
  flex-direction: column;
}

.button-align {
  width: 6vw;
}

.section {
  padding: 12px;
}

.section .col {
  margin-bottom: 24px;
}

.title-page {
  margin-top: 80px !important;
  font-size: 6vw;
  font-weight: 300;
  font-family: var(--font-family-fraunces);
}

.button-nsi {
  font-family: var(--font-family-instrument) !important;
  font-size: 3.3vw !important;
  font-weight: 400 !important;
  height: 4.5vh !important;
}

.text-description-home {
  height: 100%;
}

.desk {
  display: block;
}

.mobile {
  display: none;
}

.label {
  background-color: var(--background-color-blue);
  color: var(--background-color-white);
  margin-left: 1vw;
  padding: 1vw;
  font-size: 3vw;
  font-family: var(--font-family-instrument);
}

.buttton-default {
  span {
    font-size: 1vw;
    font-weight: 400;
    font-family: var(--font-family-instrument);
  }

}


.video-arrow {
  font-size: 7vw;
  font-family: var(--font-family-fraunces);
  font-weight: 400;
}

.arrow-right {
  margin-left: 2vw;
}

.video-title {
  font-family: var(--font-family-fraunces);
  font-size: 3vw;
  font-weight: 300;
  line-height: 1.3;
}

.video-title-agency {
  font-weight: 300;
  font-size: 1vw;
  font-family: var(--font-family-instrument);
}

.video-title-client {
  font-weight: 300;
  margin-top: -2vh;
}

.video-mdb {
  padding: 0;
}

.video-description {
  margin-top: 3vh;
  font-style: italic;
  font-family: var(--font-family-fraunces);
  padding-bottom: 10vh;
  font-weight: 300;
  font-size: 2.2vw;
  line-height: 1;
}


.line {
  width: 100%;
  height: 0.5px;
  /* Ajuste a altura conforme necessário */
  background-color: black !important;
  /* Ajuste a cor conforme necessário */
  border: none;
  /* Remove a borda padrão do <hr> */
  margin: 0;
  /* Remove margens padrão */
  box-shadow: none;
  /* Remove qualquer sombra */
}

@media (max-width: 819px) {
  .desk {
    display: none;
  }

  .mobile {
    display: block;
  }

  .section .col:last-child {
    margin-right: 4px;
  }


  .video-description {
    font-size: 5vw;
  }
}

@media (min-width: 820px) and (max-width: 1119px) {
  .desk {
    display: none;
  }

  .mobile {
    display: block;
  }

  .button-nsi {
    font-size: 3vw !important;
  }

  .label {
    font-size: 1vw !important;
  }
}

@media (min-width: 1120px) {
  .section {
    padding: 60px !important;
  }

  .video-title {
    padding-right: 0vw;
    font-size: 3vw;
  }

  .video-space {
    margin-top: 20vh;
  }

  .video-arrow {
    margin-top: 15vh;
    font-size: 3vw;
    font-family: var(--font-family-fraunces);
    font-weight: 400;
  }

  .arrow-right {
    margin-left: 2vw;
  }

  .button-nsi {
    font-size: 1vw !important;
    height: 7vh !important;
  }

  .label {
    font-size: 0.7vw !important;
    padding: 0.5vw;
  }
}

@media (min-width: 1280px) {
  .button-nsi {
    height: 6vh !important;
  }
}

@media (min-width: 1559px) {
  .button-nsi {
    height: 5vh !important;
  }
}

@media (min-width: 1728px) {
  .button-nsi {
    font-size: 0.9vw !important;
  }
}

/*

@media (max-width: 360px) {}

@media (min-width: 820px) {}

@media (min-width: 1120px){}

@media (min-width: 361px) and (max-width: 375px) {}

@media (min-width: 376px) and (max-width: 390px) {}

@media (min-width: 391px) and (max-width: 393px) {}

@media (min-width: 394px) and (max-width: 428px) {}

@media (min-width: 429px) and (max-width: 430px) {}

@media (min-width: 431px) and (max-width: 480px) {}

@media (min-width: 481px) and (max-width: 819px) {}

@media (min-width: 820px) and (max-width: 1119px) {}

@media (min-width: 1120px) and (max-width: 1279px) {}

@media (min-width: 1280px) and (max-width: 1558px) {}

@media (min-width: 1559px) and (max-width: 1727px) {}

@media (min-width: 1728px) {}
*/

:root {
  /* Backgrounds */
  --background-color-blue: #0054FF;
  --background-color-white: white;
  --background-color-green: #D5F434;

  /* Fonts */
  --font-family-fraunces: "Fraunces", serif;
  --font-family-instrument: "Instrument Sans", sans-serif;

  /* Images */
  --image-border-radius: 80% 30% 80% 30%;
  --image-width: 100%;
  --image-position: absolute;
  --z-index-image-ellipse: 1;
  --z-index-ellipse: 2;
  /* Button */
}

@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Instrument+Sans:ital,wght@0,300..700;1,300..700&display=swap')