.home-section-four {
  height: 100%;
  background-color: var(--background-color-green);

  .label-section-four {
    margin-top: 4vh;
  }

  .title-home-section-four {
    margin-left: 0vw;
    font-family: var(--font-family-fraunces);
    font-weight: 300;
  }

  .text-home-section-four {
    margin-left: 0vw;
    font-family: var(--font-family-instrument);
    font-size: 4vw;
    font-weight: 400;
  }

  .image-home-section-four {
    margin-top: 5vh;

    img {
      margin-left: 1vw;
    }
  }

  .build-two-home-section-four {
    margin-top: 0.5vh;
  }

  .text-description-section-four {
    margin-top: 15%;
  }
}


@media (max-width: 819px) {
  .home-section-four {
    .text-home-section-four {
      margin-top: 5vh;
    }

    .build-section-four {
      margin-bottom: 2.9vh;
      margin-left: 1.2vw;
    }

    .build-man-home {
      img {
        padding-left: 1vw !important;
      }
    }

    .man-section-four {
      img {
        padding-left: 1vw !important;
      }
    }
  }
}

@media (min-width: 428px) {
  .home-section-four {

    .build-man-home {
      img {
        padding-left: 3vw !important;
      }
    }

    .man-section-four {
      img {
        padding-left: 1vw !important;
      }
    }
  }
}

@media (min-width: 820px) and (max-width: 1119px) {
  .home-section-four {

    .text-home-section-four {
      margin-top: 5vh;
    }
  }
}

@media (min-width: 1120px) {
  .home-section-four {
    .title-home-section-four {
      margin-top: 2vh;
    }

    .text-home-section-four {
      margin-top: 12%;
      font-size: 1.5vw;
    }

    .image-home-section-four {
      margin-top: 0vh;
    }

    .button-nsi {
      margin-left: 0vw;
    }

    .build-two-home-section-four {
      margin-top: -1vh;
    }
  }


  .position-relative {
    position: relative;
    /* Necessário para posicionamento absoluto */
  }

  .bottom-aligned-absolute {
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 1280px) {
  .text-home-section-four {
    margin-top: 5% !important;
  }

  .home-section-four {
    .build-two-home-section-four {
      margin-top: -0.2vh;
    }
  }
}

@media (min-width: 1559px) {
  .text-home-section-four {
    margin-top: 12% !important;
  }
  .home-section-four {
    .build-two-home-section-four {
      margin-top: 0.3vh;
    }
  }
}

@media (min-width: 1761px) {
  .home-section-four {
    .build-two-home-section-four {
      margin-top: 0.7vh;
    }
  }
}