.home-section-seven {
  height: 100%;
  background-color: var(--background-color-green);

  .label {
    margin-left: 0 !important;
  }

  .title-home-section-seven {
    font-family: var(--font-family-fraunces) !important;
    font-weight: 300;
  }

  .text-home-section-seven {
    font-family: var(--font-family-instrument);
    font-size: 4vw;
    font-weight: 400;
  }

  .see-impossible-home {
    margin-top: 2vh;
    margin-left: 1vw;
    font-size: 6vw;
    font-weight: 300;
    line-height: 1;
    font-family: var(--font-family-fraunces) !important;
  }  
}

@media (min-width: 360px) {
  .home-section-seven {
    .see-impossible-home-buttons {
      margin-left: 2vw;
    }
  }
}

@media (min-width: 1120px) {
  .home-section-seven {
    .text-home-section-seven {
      margin-top: 10vh;
      font-size: 1.5vw;
    }

    .see-impossible-home {
      font-size: 3.5vw;
      margin-left: 0vw;
    }

    .see-impossible-home-buttons {
      margin-left: 1vw;
    }
  }
}

@media (min-width: 1280px) {
  .home-section-seven {
    .text-home-section-seven {
      margin-top: 11vh;
    }
  }
}


@media (min-width: 1559px) {
  .home-section-seven {
    .text-home-section-seven {
      margin-top: 12vh;
    }
  }
}

@media (min-width: 1761px) {
  .home-section-seven {
    .text-home-section-seven {
      margin-top: 13.5vh;
    }
  }
}