.label {
  margin-left: 0vw;
}

.award-video {
  color: black;
  background-color: var(--background-color-green);
  font-weight: 400;
}

.title-director {
  margin-top: 12vh;
  font-family: var(--font-family-fraunces);
  font-size: 6vw;
  font-weight: 300 !important;
}

.name-director {
  margin-top: 5vh;
  color: #0054FF;
  font-family: var(--font-family-fraunces);
  font-size: 12vw;
  font-weight: 300 !important;
  margin-bottom: 5vh;
}

.description-director {
  font-style: italic;
  font-family: var(--font-family-fraunces);
  font-size: 5.5vw;
  font-weight: 300 !important;
  line-height: 1;
}

.movie-director {
  margin-top: 5vh;
  font-family: var(--font-family-fraunces);
  font-size: 12vw;
  font-weight: 300 !important;
}

.movie-director-2 {
  margin-top: -2vh;
}

@media (min-width: 1120px) {
  .video-mdb {
    padding: 0;
  }


  .image-director {
    margin-top: -18vh;
    z-index: -1;
  }

  .description-director {
    font-size: 2vw;
  }

  .movie-director-1 {
    margin-left: 13vw;
  }

  .movie-director-2 {
    margin-top: -2vh;
  }
}