.home-section-three {
  height: 100%;
  background-color: var(--background-color-green);

  .title-home-section-three {
    text-align: center;
    font-size: 8.5vw;
    font-family: var(--font-family-fraunces);
    font-style: italic;
    font-weight: 300;

    
  }

  .title-home-section-three span {
    font-family: var(--font-family-instrument);
    font-style: normal;
  }

  .our-work-home-section-three {
    margin-left: 1.5vw;
    max-width: 100%;
  }

  .text-home-section-three {
    margin-left: 1vw;
    font-family: var(--font-family-fraunces);

    
  }

  .text-home-section-three h2 {
    font-size: 9vw !important;
    margin-top: 5vh !important;
    font-weight: 300;
    line-height: 1;
  }

  .text-description-home-section-three {
      padding-top: 65%;
      text-align: left;
      font-family: var(--font-family-instrument);
      font-size: 2vw;
  }
}

@media (min-width: 377px) and (max-width: 819px) {
  .home-section-three {
    .text-home-section-three {
      p {
        padding-top: 30%;
      }
    }
  }
}

@media (min-width: 1120px) {
  .home-section-three {

    .text-home-section-three {
      h2 {
        font-size: 3.6vw !important;
        margin-top: 0vh !important;
      }

      p {
        margin-top: 0;
        /* Reseta a margem para usar o flexbox */
        text-align: left;
        font-family: var(--font-family-instrument);
        font-size: 2vw;
        margin-right: 4vw;
        font-weight: 400;
      }
    }
  }
}

@media (min-width: 1280px) {
  .home-section-three {
    .text-home-section-three {
      h2 {
        font-size: 3.7vw !important;
      }
    }
  }
}

@media (min-width: 1559px) {
  .home-section-three {
    .text-home-section-three {
      h2 {
        font-size: 3.9vw !important;
      }
    }
  }
}

@media (min-width: 1761px) {
  .home-section-three {
    .text-home-section-three {
      h2 {
        font-size: 4.1vw !important;
      }
    }
  }
}