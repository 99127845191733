.section-about {
  background-color: #D5F434;
}

.title-about {
  margin-top: 15vh;
  font-family: var(--font-family-fraunces);
}

.subtitle-about {
  font-weight: 300 !important;
  font-family: var(--font-family-instrument) !important;
}

.subtitle-right {
  font-family: var(--font-family-fraunces);
  font-size: 3vw;
  line-height: 1;
  font-weight: 300;
}

.link-about {
  color: var(--background-color-blue);
  font-style: italic;
}

.elipses-about img {
  margin-left: 1.5vw;
  max-width: 70% !important;
  margin-left: 0vw;
  height: auto;
}

.elipse-one {
  margin-top: 10vh;
}

.second-section-about {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.text-about-impossible {
  margin-top: 5vh;
  font-family: var(--font-family-fraunces);
  font-weight: 300;
  line-height: 1;
}

.subtitle-about {
  font-family: var(--font-family-instrument);
  font-weight: 400;

}

.elipses-about img {
  margin-left: -2vw;
  max-width: 88%;
  height: auto;
}


@media (min-width: 360px) {
  .title-about {
    font-family: var(--font-family-fraunces);
  }

  .subtitle-about {
    margin-top: 10vh;
    margin-right: 0vw;
  }

  .subtitle-right {
    margin-top: 5vh;
    font-size: 9vw;
  }

  .text-about-impossible {
    font-size: 5.5vw;
  }
}

@media (min-width: 820px) {
  .title-about {
    margin-top: 10vh;
  }

}

@media (min-width: 1120px) {
  .title-about {
    font-family: var(--font-family-fraunces);
  }

  .subtitle-about {
    margin-top: 10vh;
    font-size: 1.15vw;
  }

  .subtitle-one {
    margin-top: -15vh !important;
  }

  .subtitle-right {
    margin-top: 1vh;
    font-size: 3.5vw;
  }

  .terceiro-texto {
    margin-top: -15vh !important;
  }

  .second-section-about {
    margin-top: 10vh;
  }

  .text-about-impossible {
    margin-top: 5vh;
    font-family: var(--font-family-fraunces);
  }

  .subtitle-about {
    font-size: 1.23vw;
  }

  .text-about-impossible {
    margin-top: -20vh;
    font-size: 3.3vw;
  }
}

@media (min-width: 1280px) {
  .subtitle-right {
    margin-top: 3vh;
  }
}

@media (min-width: 1761px) {
  .subtitle-right {
    margin-top: 10vh !important;
  }
}