  .section-end {
    background: #0054FF;
    padding-top: 10vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .elipses-end img {
    position: absolute;
    z-index: -1;
    margin-top: 2vh;
    width: 33vw;
  }

  .elipses-end-1 {
    margin-left: -4vw;
  }

  .elipses-end-3 {
    margin-left: 4vw;
  }

  .title-end {
    text-align: center;
    font-size: 8vw;
    text-align: center;
    color: white;
    font-family: "Fraunces", serif;
    font-weight: 300;
    line-height: 1;
  }

  .subtitle-impossible-end {
    text-align: left;
    margin-top: 18vh;
    margin-left: 3.5vw;
    font-size: 6vw;
    line-height: 1;
    font-family: "Fraunces", serif;
    color: white;
  }

  .menu-footer-home {
    font-family: 'Instrument Sans', sans-serif;
    font-size: 3.5vw;
    color: white;
  }

  .social-footer-home {
    font-family: 'Instrument Sans', sans-serif;
    font-size: 3.5vw;
    color: white;
  }

  .footer {
    height: 30vh;
  }

  .line-footer-home {
    margin-top: 24vh;
    content: '';
    position: relative;
    bottom: 0;
    left: 50%;
    width: 100vw !important;
    /* Forces the line to occupy 100% of the screen width */
    height: 0.5px;
    /* Adjust the thickness of the line */
    background-color: rgb(168, 0, 0);
    /* Sets the line color to black */
    transform: translateX(-50%);
    /* Centers the line on the screen */
    overflow: hidden;
  }

  .link-footer {
    color: white !important;
    font-weight: 300;
  }

  @media (max-width:819px) {
    .logo-footer-home {
      text-align: center;
      margin-top: 4vh;
    }

    .menu-footer-home {
      text-align: center;
    }

    .social-footer-home {
      text-align: center;
    }
  }


  @media (min-width: 1120px) {
    .logo-footer-home {
      text-align: left !important;
      margin-top: 2vh;
    }

    .menu-footer-home {
      font-size: 0.8vw !important;
      margin-top: 4vh;
      text-align: center;
    }

    .social-footer-home {
      text-align: right !important;
      font-size: 0.8vw;
      margin-top: 4vh;
    }

    .footer {
      height: 0vh;
    }


    .elipses-end img {
      margin-top: 20vh !important;
    }

    .title-end {
      margin-top: 10vh;
      line-height: 1;
      font-size: 6vw !important;
      font-weight: 300;
    }

    .subtitle-impossible-end {
      font-size: 3vw !important;
      margin-top: 60vh !important;
    }
  }



  @media (min-width: 1280px) {
    .section-end {
      .logo-footer-home {
        text-align: left !important;
        margin-top: 1vh;
      }

      .menu-footer-home {
        margin-top: 3vh;
        font-size: 0.8vw !important;
      }

      .social-footer-home {
        text-align: right !important;
        font-size: 0.8vw;
        margin-top: 3vh;
      }
    }

    .elipses-end img {
      margin-top: 20vh !important;
    }

    .title-end {
      margin-top: 10vh;
      line-height: 1;
      font-size: 6vw !important;
      font-weight: 300;
    }

    .subtitle-impossible-end {
      font-size: 3vw !important;
      margin-top: 60vh !important;
    }
  }

  @media (min-width: 1559px) {
    .logo-footer-home {
      text-align: left !important;
      margin-top: 1vh;
    }

    .menu-footer-home {
      margin-top: 2.5vh;
      font-size: 0.8vw !important;
    }

    .social-footer-home {
      text-align: right !important;
      font-size: 0.8vw;
      margin-top: 2.5vh;
    }


    .elipses-end img {
      margin-top: 20vh !important;
    }

    .title-end {
      margin-top: 10vh;
      line-height: 1;
      font-size: 6vw !important;
      font-weight: 300;
    }

    .subtitle-impossible-end {
      font-size: 3vw !important;
      margin-top: 60vh !important;
    }
  }

  @media (min-width: 1728px) {
    .logo-footer-home {
      text-align: left !important;
      margin-top: 1vh;
    }

    .menu-footer-home {
      margin-top: 2vh;
      font-size: 0.8vw !important;
    }

    .social-footer-home {
      text-align: right !important;
      font-size: 0.8vw;
      margin-top: 2vh;

    }

    .elipses-end img {
      margin-top: 20vh !important;
    }

    .title-end {
      margin-top: 10vh;
      line-height: 1;
      font-weight: 300;
    }

    .subtitle-impossible-end {
      font-size: 3vw !important;
      margin-top: 60vh !important;
    }
  }