/*safari antigo*/

.title-home-section-1 {
  padding-top: 10vh;
  font-family: var(--font-family-fraunces);
  font-size: 6.5vw;
  font-weight: 300;
  line-height: 1;
  padding-bottom: 10vh;
}

.impossible-home-section-1 {
  color: white;
}

.image-woman-section-1,
video {
  border-radius: var(--image-border-radius);
  max-width: var(--image-width);
  width: 100vw;
  -webkit-border-radius: var(--image-border-radius);
  -webkit-max-width: var(--image-width);
  -webkit-width: 100vw;
}

.image-woman-section-1 {
  text-align: center;
  z-index: 1;
}

.elipse-1-section-1 {
  margin-top: 10vh;
  -webkit-margin-top: 10vh;
  margin-left: -38vw;
  -webkit-margin-left: -38vw;
  /* Safari */
}

.elipse-2-section-1 {
  margin-left: -28vw;
}

.elipse-3-section-1 {
  margin-left: -18vw;
}

.elipse-4-section-1 {
  margin-left: -8vw;
}

.elipse-5-section-1 {
  margin-left: 2vw;
}

.elipse-6-section-1 {
  margin-left: 3vw;
}

.elipse-7-section-1 {
  margin-left: 10vw;
}

.elipse-section-1 {
  position: absolute;
  z-index: 0;
  width: 40vw;
}

.button-home-section-1 {
  text-align: center;
}

.button-nsi-home-section-1 {
  font-family: var(--font-family-instrument) !important;
  font-size: 3.3vw !important;
  font-weight: 400 !important;
  height: 4.5vh !important;
}

.home-section-1 {
  height: 130vh;
  background-color: var(--background-color-blue);
  overflow: hidden;
  z-index: 1;
  position: relative;
}


@media (min-width: 360px) {
  .home-section-1 {
    height: 100vh;
  }

  .title-home-section-1 {
    padding-top: 12vh;
    font-size: 14vw !important;
  }

  .elipse-home-section-1 {
    padding-top: 10vh;
  }
}


@media (min-width: 819px) {
  .home-section-1 {
    height: 100vh;
  }

  .title-home-section-1 {
    padding-top: 12vh;
  }

  .elipse-home-section-1 {
    padding-top: 3vh;
  }

  .image-woman-home-section-1 {
    padding-top: 2vh;
  }
}

@media (min-width: 820px) and (max-width: 1119px) {
  .home-section-1 {
    height: 100%;
  }

  .title-home-section-1 {
    padding-top: 12vh;
    font-size: 10vw !important;
  }

  .button-nsi-home-section-1 {
    font-size: 1.8vw;
  }
}

@media (min-width: 1120px) {
  .home-section-1 {
    height: 100%;
  }

  .title-home-section-1 {
    font-size: 5.2vw !important;
  }

  .button-nsi-home-section-1 {
    font-size: 0.8vw;
    height: 8vh !important;
  }

  .elipse-home-section-1 {
    margin-top: 5vh;
  }
}

@media (min-width: 1280px) {
  .title-home-section-1 {
    padding-right: 1vw;
    font-size: 5.5vw !important;
  }

  .button-nsi-home-section-1 {
    height: 6vh !important;
  }
}


@media (min-width: 1559px) {
  .title-home-section-1 {
    padding-right: 1vw;
    font-size: 6.5vw !important;
  }
  .button-nsi-home-section-1  {
    height: 5vh !important;
  }
}