.section-contact {
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.title-contact {
  margin-top: 12vh;
  font-family: var(--font-family-fraunces);

}

.address-contact {
  z-index: 2;
  margin-top: 10vh;
  font-family: var(--font-family-fraunces);
  font-size: 6vw;
  font-weight: 300;
  line-height: 1;
}

.group-contact {
  z-index: 2;
  line-height: 1;
}

.email-contact {
  color: #0054FF;
  font-family: var(--font-family-fraunces);
  font-size: 6vw;
  font-weight: 300;
}

.phone-contact {
  margin-top: -1vh;
  font-family: var(--font-family-fraunces);
  font-size: 6vw;
  font-weight: 300;
}

.elipse {
  position: absolute;
  width: 40vw;
  margin-top: -16vh;
}

.elipse-1 {
  margin-left: -15vw;
  z-index: 1;
}

.elipse-2 {
  margin-left: -5vw;
  z-index: 1;
}

.elipse-3 {
  margin-left: 5vw;
  z-index: 1;
}

.maps-contact {
  z-index: 2;
  margin-top: -3vh;
}

iframe {
  height: 400px;
  width: 100% !important;
  border: 0 !important;
}

@media (min-width: 1120px) {

  .address-contact {
    font-size: 5vw;
  }

  .group-contact {
    margin-top: 28vh;
  }

  .email-contact {
    font-size: 2vw;
  }

  .phone-contact {
    margin-top: -1vh;
    font-size: 2vw;
  }

  iframe {
    height: 480px;
  }

}

@media (min-width: 1280px) {
  iframe {
    height: 560px;
  }
}

@media (min-width: 1559px) {
  iframe {
    height: 760px;
  }
}