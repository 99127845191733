.section-experiences {
  background-color: #D5F434;
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.subtitle-experiences {
  margin-top: 1vh;
  font-weight: 400;
  font-family: var(--font-family-instrument);
  font-size: 1.15vw !important;
}

.subtitle-experiences-right {
  font-family: var(--font-family-fraunces);
  line-height: 1;
  font-weight: 300;
  padding-bottom: 4vh;
}

.link-experiences {
  color: var(--background-color-blue);
  font-style: italic;
}

.elipses-experiences img {
  margin-left: 1.5vw;
  max-width: 70%;
  height: auto;
}

.second-section-experiences {
  margin-top: 10vh;
  margin-bottom: 10vh;
  z-index: 0;
  overflow: hidden;
  position: relative;
}


.subtitle-experiences {
  font-family: var(--font-family-instrument);
  font-size: 5.3vw;
}

.second-title-experiences {
  z-index: 2;
  font-weight: 300;
  font-family: var(--font-family-fraunces);
  font-size: 9vw;
}

.title-two {
  margin-top: -3vh;
  margin-bottom: 10vh;
}

.elipse {
  position: absolute;
  width: 40vw;
  margin-top: 0vh;
  transform: rotate(185deg);
}

.elipse-1 {
  margin-left: -15vw;
  z-index: 1;
}

.elipse-2 {
  margin-left: -5vw;
  z-index: 1;
}

.elipse-3 {
  margin-left: 5vw;
  z-index: 1;
}




@media (min-width: 360px) {
  .title-about {
    font-family: var(--font-family-fraunces);
  }

  .subtitle-experiences {
    margin-top: 0vh;
    padding-bottom: 8vh;
    margin-right: 0vw;
    font-size: 9.5vw;
  }

  .subtitle-experiences-right {
    font-size: 8vw;
  }

  .second-title-experiences {
    font-size: 12vw;
  }

  .title-two {
    margin-top: -6vh;
  }
}


@media (min-width: 820px) {
  .title-about {
    margin-top: 10vh;
  }
}

@media (min-width: 1120px) {

  .title-experiences {
    font-family: var(--font-family-fraunces);
    font-size: 8vw;
  }

  .subtitle-experiences {
    margin-top: 12vh;
    font-size: 1.15vw;
  }

  .subtitle-experiences-right {
    margin-top: 12vh;
    font-size: 3.5vw;
    padding-bottom: 8vh;
  }

  .second-section-experiences {
    margin-top: 10vh;
  }

  .text-about-impossible {
    margin-top: 5vh;
    font-family: var(--font-family-fraunces);
  }

  .subtitle-experiences {
    font-size: 2vw;
  }

  .title-two {
    margin-top: -10vh;
  }
}