.home-section-six {
  padding-top: 8vh;
  height: 100%;
  background-color: var(--background-color-green);
  .label {
    margin-left: 0 !important;
  }

  .title-home-section-six {
    margin-top: 2vh;
    font-family: var(--font-family-fraunces) !important;
    font-weight: 300;
  }

  .text-home-section-six {
    font-family: var(--font-family-instrument);
    font-size: 4vw;
    font-weight: 400;
  }
}

@media (min-width: 390px) {
  .home-section-six {
  }
}

@media (min-width: 427px) {
  .home-section-six {
  }
}

@media (min-width: 1120px) { 
  .home-section-six {
    .text-home-section-six {
      font-size: 1.5vw;
      margin-top: 16vh;
    }
  }
}

@media (min-width: 1280px) { 
  .home-section-six {
    .text-home-section-six {
      margin-top: 17vh;
    }
  }
}


@media (min-width: 1559px) { 
  .home-section-six {
     .text-home-section-six {
      margin-top: 22vh;
    }
  }
}


@media (min-width: 1761px) { 
  .home-section-six {
     .text-home-section-six {
      margin-top: 23vh;
    }
  }
}