.home-section-two {
  margin-top: 5vh;
  position: relative;
  overflow: hidden;
}

.text-home-section-two{
  margin-top: -40vh;
  position: absolute;
  text-align: center;
  z-index: 1;
  transform: translateZ(0);
  font-family: var(--font-family-fraunces);
  font-size: 8vw;
  font-weight: 300;
  line-height: 1;
}

.second-text-section-two {
  margin-top: -15vh;
  font-weight: 300;
}

.camaro-home-section-two img,
.dress-home-section-two img,
.studio-home-section-two img {
  width: 100%;
  position: relative;
  z-index: 0;
}

.dress-home-section-two{
  text-align: right;
}

.studio-home-section-two {
  margin-top: 90vh;
  padding-bottom: 10vh;
}

@media (min-width: 360px) {
  .text-home-section-two{
    margin-top: -5vh;
  }
  .second-text-section-two {
    margin-top: 20vh;
  }
  .studio-home-section-two {
    margin-top: 10vh;
    margin-bottom: 0vh;
  }
}

@media (min-width: 377px) and (max-width: 819px) {
  .home-section-two .title-home-section-two p {
    font-size: 3.5vw;
  }
}

@media (min-width: 820px) and (max-width: 1119px) {
  .second-text-section-two {
    margin-top: 35vh;
    margin-bottom: 30vh;
  }
}

@media (min-width: 1120px) {
  .text-home-section-two{
    margin-top: -42vh;
    font-size: 6.5vw;
  }
  .second-text-section-two {
    margin-top: 30vh;
  }
  .studio-home-section-two {
    margin-top: 15vh;
    margin-bottom: 50vh;
  }
}

@media (min-width: 1281px) {
  .second-text-section-two {
    margin-top: 20vh;
    margin-bottom: 30vh;
  }
}

@media (min-width: 1560px) {
  .second-text-section-two {
    margin-top: 30vh;
    margin-bottom: 30vh;
  }
}