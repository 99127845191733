.offcanvas-custom {
  clip-path: ellipse(50px 25px at 90% -10%);
  -webkit-clip-path: ellipse(50px 25px at 90% -10%);
  transition: clip-path 2s ease, -webkit-clip-path 2s ease;
  pointer-events: all;
  will-change: clip-path;
}

.offcanvas-custom.open {
  clip-path: ellipse(41% 53% at 95% 6%);
  -webkit-clip-path: ellipse(100% 100% at 50% 50%);
  transition: clip-path 2s ease 0.1s, -webkit-clip-path 2s ease 0.1s;
  pointer-events: all;
}

.section-option-menu .nav-link:hover {
  color: #0054FF !important;
  text-decoration: none;
}


body.modal-open {
  padding-right: 0 !important;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Define o tamanho máximo do contêiner Navbar.Brand */
.navbar-brand {
  /* Ajuste conforme necessário */
  display: flex;
  align-items: center;
  overflow: hidden;
}

.logo-menu {
  max-width: 80px; /* Garante que a largura máxima seja 100% do contêiner */
  height: auto;
}
.logo-menu-open {
  padding-right: 10vw;
  width: auto;
  max-width: 110px; /* Garante que a largura máxima seja 100% do contêiner */
  height: auto;
}
/* Ajusta a imagem dentro do contêiner */

/* 
.custom-cursor a {
  cursor: url('../assets/elipse-menu.png'), auto;
} */



.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.btn-close {
  margin-right: 3vw !important;
}

.navbar {
  margin-bottom: 0;
  /* Remove margin below the navbar */
  box-shadow: none;
  /* Remove any box-shadow */
}

#root>nav>div>span>span {
  font-weight: 400 !important;
  color: black;
}

.modal-open body {
  padding-right: 0px !important;
}

.navbar-toggler {
  border: none;
}



.navbar-title-text {
  font-family: 'Instrument Sans', sans-serif !important;
  font-size: 0.9vw !important;
  font-weight: 600 !important;
}

.offcanvas.show {
  width: 100%;
}

.section-menu {
  overflow: hidden;
  background-color: #D5F434;
  text-align: center;
  border-bottom: 1px solid black;
}

.section-option-menu {
  padding-left: 4vw;
  background-color: #D5F434;
  overflow: hidden;
}

.title-menu {
  width: 100%;
}


.section-option-menu {
  background-color: #D5F434;
}

.nav-link {
  font-family: 'Instrument Sans', sans-serif;
  font-weight: 600;
  font-size: 4vw;
  /* Aumenta o tamanho do texto */
  color: black;
  /* Cor inicial do texto */
  text-align: left;
  /* Centraliza o texto */
  padding: 1.4rem 0;
  /* Adiciona espaçamento entre as opções */
  display: block;
  /* Animação para mudança de cor */
  position: relative;
  /* Necessário para a linha ocupando a tela completa */
  margin-top: 0vh;
}

.movies-menu {
  margin-top: -3vh !important;
}

.section-option-menu .nav-link:hover {
  color: #0054FF;
  /* Cor do texto ao passar o mouse */
  text-decoration: none;
  /* Remove a sublinhado padrão */
}

.section-option-menu .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 147vw;
  /* Força a linha a ocupar 100% da largura da tela */
  height: 0.5px;
  background-color: black;
  transform: translateX(-50vw);
  overflow: hidden;
  /* Ajusta a linha para ocupar 100% da tela */
}

.section-option-menu .nav-link:last-child::after {
  display: none;
  /* Remove a linha preta da última opção */
}

@media (min-width:360px) {
  .section-option-menu {
    padding-left: 4.5vw;
  }

}

@media (max-width: 375px) {

  .navbar-title-text {
    display: none;
  }

  .section-option-menu .nav-link {
    font-size: 11vw;
  }

  .section-option-menu .nav-link::after {
    min-width: 145.2vw;
  }
}

@media (min-width: 378px) and (max-width: 427px) {
  .navbar-title-text {
    display: none;
  }

  .section-option-menu .nav-link {
    font-size: 12vw;
  }

  .section-option-menu .nav-link::after {
    min-width: 145vw;
  }
}

@media (min-width: 428px) and (max-width: 480px) {
  .navbar-title-text {
    display: none;
  }

  .section-option-menu .nav-link {
    font-size: 12vw;
  }

  .section-option-menu .nav-link::after {
    min-width: 146vw;
  }
}

@media (min-width: 481px) and (max-width: 768px) {}

@media (min-width: 481px) and (max-width: 768px) {}

@media (min-width: 769px) and (max-width: 1024px) {}

@media (min-width: 1120px) {
  .logo-menu {
    padding-left: 4vw; 
    padding-right: 3vw;
    max-width: 190px; /* Garante que a largura máxima seja 100% do contêiner */
    height: auto;
  }
  .logo-menu-open {
    padding-left: 4vw; 
    padding-right: 3vw;
    max-width: 190px; 
  }
  .logo-menu-nsi {
    padding-left: 50px;
  }

  .button-menu-icon {
    padding-right: 38px;
  }

  .section-option-menu {
    padding-left: 5.8vw;
  }

  .section-option-menu .nav-link {
    font-size: 3.5vw;
  }

  .section-option-menu .nav-link::after {
    min-width: 190vw !important;
  }
}

@media (min-width: 1280px) {
  .logo-menu {
    padding-left: 4vw; 
    padding-right: 3vw;
    max-width: 200px; /* Garante que a largura máxima seja 100% do contêiner */
    height: auto;
  }
  .logo-menu-open {
    padding-left: 4vw; 
    padding-right: 3vw;
    max-width: 200px; 
  }
  .section-option-menu {
    padding-left: 5vw;
  }

  .movies-menu {
    margin-top: -2vh !important;
  }

  .logo-menu-one {
    margin-left: 3.5vw;
  }

  .logo-menu-two {
    margin-left: 3vw;
  }
}

@media (min-width: 1559px) {
  .section-option-menu {
    padding-left: 4vw;
  }

  .section-option-menu .nav-link {
    font-size: 4.5vw;
  }

  .logo-menu {
    padding-left: 3vw; 
    padding-right: 1vw;
    max-width: 210px; /* Garante que a largura máxima seja 100% do contêiner */
    height: auto;
  }
  .logo-menu-open {
    padding-left: 2.8vw; 
    padding-right: 1vw;
    max-width: 210px; 
  }
}

@media (min-width: 1728px) {
  .section-option-menu {
    padding-left: 3.5vw;
  }

  .section-option-menu .nav-link {
    font-size: 5vw;
  }
  .logo-menu-one {
    margin-left: 2.5vw;
  }
  
  .logo-menu-two {
    margin-left: 2.1vw;
  }
}